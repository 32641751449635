import api from '@/plugins/api'
import { StatusResponse } from '@/services/types'

type LoginResponse = {
  _2faToken: string
}

const AuthService = {
  verifyActivateUserToken: (token: string): Promise<StatusResponse<undefined>> => {
    return api().post('/activate-user/validate-token', { token })
  },

  activateUser: (token: string, password: string, passwordConfirmation: string): Promise<StatusResponse<undefined>> => {
    return api().post('/activate-user', { password, passwordConfirmation, token })
  },

  login: (email: string, password: string): Promise<StatusResponse<LoginResponse>> => {
    return api().post('/login', { email, password })
  },
}

export default AuthService
