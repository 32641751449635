
















import Vue from 'vue'

export default Vue.extend({
  name: 'SubmitButton',
  props: { color: { type: String, default: 'primary' } },
  computed: {
    btnColor() {
      if (this.color === 'green') return 'primary'
      if (this.color === 'red') return 'red'
      return 'primary'
    },
  },
})
