import { reactive } from '@vue/composition-api'
import Vue from 'vue'

export default function (): {
  errors: { value: { [x: string]: string[] } }
  clearFieldError: (key: string) => void
  clearErrors: () => void
} {
  const errors = reactive<{ value: Record<string, string[]> }>({ value: {} })

  function clearFieldError(key: string) {
    Vue.delete(errors.value, key)
  }

  function clearErrors() {
    Object.keys(errors.value).forEach((key: string) => clearFieldError(key))
  }

  return { errors, clearFieldError, clearErrors }
}
