








































import AuthService from '@/services/authentication.service'
import { errorMessages } from '@/utils/helpers'
import QuickLoginButton from '@/components/QuickLoginButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'
import { defineComponent } from '@vue/composition-api'
import { useErrors } from '@/components/composables/useErrors'

export default defineComponent({
  setup() {
    const { errors, clearFieldError } = useErrors()
    return { errors, clearFieldError }
  },
  name: 'Login',
  components: { SubmitButton, QuickLoginButton },
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      showPassword: false,
      errorMsg: '',
    }
  },
  methods: {
    toggleShowPassword(): void {
      this.showPassword = !this.showPassword
    },
    async login(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const { data } = await AuthService.login(this.email, this.password)
        this.$toast.success(data.message)
        const token = data.data._2faToken
        await this.$router.push(`two-factor-auth?token=${token}`)
      } catch (err) {
        if (err) {
          this.errorMsg = errorMessages(err.response.data.message)
          this.errors.value = err.response.data.errors || {}
          setTimeout(() => (this.errorMsg = ''), 3000)
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
  computed: {
    passwordFieldType(): string {
      return this.showPassword ? 'text' : 'password'
    },
    passwordFieldIcon(): string {
      return this.showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
    },
  },
})
